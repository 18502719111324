/**
 * Recaptcha
 * On this site, the form buttons are disabled after sending so...
 * For each instance of recaptcha_response add a unique recaptcha response to verify
 * Not possible to verify twice with same response, so if want to allow sending twice need to make it refresh the response in the input
 */

export default function getRecaptchaResponse() {
  const siteKey = webroll_js_data.recaptcha_site_key;
  const env = webroll_js_data.env;
  const responseInput = document.querySelectorAll(
    'input[name="recaptcha_response"]'
  );

  if (responseInput && responseInput[0] && env === "production") {
    grecaptcha.ready(() => {
      responseInput.forEach((input) => {
        grecaptcha
          .execute(siteKey, { action: "recaptcha_validate" })
          .then((token) => {
            input.value = token;
          });
      });
    });
  }
}
