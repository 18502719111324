import theme from "./tw-theme";

function initGoogleMap() {
  /**
   * General options
   */
  var $lat = 53.50789925272015,
    $lng = -1.3259956011111713,
    $zoom = 6, // set initial zoom then resize if needed below
    $name = "CCL Road Haulage";

  /**
   * Use here or handle center & zoom responsively below
   */
  // var $centerLat = $lat,
  // $centerLng = $lng;

  /**
   * Styles - Set here on with mapId below
   * @ref - https://developers.google.com/maps/documentation/javascript/cloud-based-map-styling
   */
  // var $styles = [
  //     {
  //     },
  // ];

  /**
   * Construct the map with the above general options and styles
   */
  var infowindow = new google.maps.InfoWindow();
  var map = new google.maps.Map(document.getElementById("gmap"), {
    zoom: $zoom,
    center: new google.maps.LatLng($lat, $lng),
    // styles: $styles,
    mapId: webroll_js_data.google_map_id
  });

  /**
   * Dump the center - Useful if need different center to $lat & $lng
   */
  google.maps.event.addListener(map, "center_changed", function () {
    var center = this.getCenter();
    var latitude = center.lat();
    var longitude = center.lng();
    console.log("current latitude is: " + latitude);
    console.log("current longitude is: " + longitude);
  });

  /**
   * Handle responsive center & zoom
   */
  google.maps.event.addDomListener(window, "resize", function () {
    var $centerLatLng,
      $smCenterLat = "53.2065918778692",
      $smCenterLng = "-1.4974699185083806",
      $mdCenterLat = "53.28806936992856",
      $mdCenterLng = "-13.157808224047471",
      $lgCenterLat = $mdCenterLat,
      $lgCenterLng = $mdCenterLng,
      $xlCenterLat = $mdCenterLat,
      $xlCenterLng = $mdCenterLng;

    // md-max
    if (window.matchMedia("(max-width: 767px)").matches) {
      $centerLatLng = new google.maps.LatLng($smCenterLat, $smCenterLng);
      $zoom = 6;
    }

    // md-min & lg-max
    else if (
      window.matchMedia("(min-width: 768px) and (max-width: 991px)").matches
    ) {
      $centerLatLng = new google.maps.LatLng($mdCenterLat, $mdCenterLng);
      $zoom = 6;
    }

    // lg-min & xl-max
    else if (
      window.matchMedia("(min-width: 992px) and (max-width: 1199px)").matches
    ) {
      $centerLatLng = new google.maps.LatLng($lgCenterLat, $lgCenterLng);
      $zoom = 6;
    }

    // min-xl
    else if (window.matchMedia("(min-width: 1200px)").matches) {
      $centerLatLng = new google.maps.LatLng($xlCenterLat, $xlCenterLng);
      $zoom = 6;
    }

    map.setCenter($centerLatLng);
    map.setZoom($zoom);
  });

  google.maps.event.trigger(window, "resize");

  /**
   * Markers & circles
   * Add array of markers to be displayed on the map & attach them to new infowindows
   * Also use $locations array to generate circles/radiuses around them - See commented code below
   */
  // set the locations
  var $locations = [
    {
      lat: $lat,
      lng: $lng,
      name: $name,
      /**
       * Use following for circles
       */
      center: { lat: $lat, lng: $lng },
      radius: 59166
    }
  ];
  // helper function
  function wrMarker(loc) {
    var marker = new google.maps.Marker({
      position: new google.maps.LatLng(loc.lat, loc.lng),
      map: map
    });
    google.maps.event.addListener(marker, "click", function () {
      infowindow.close(); // Close previously opened infowindow
      infowindow.setContent(`<div id="infowindow">${loc.name}</div>`);
      infowindow.open(map, marker);
    });
  }
  // output the circles - @todo, integrate into above function
  // for (var $location in $locations) {
  //   new google.maps.Circle({
  //     strokeColor: "",
  //     strokeOpacity: 0,
  //     strokeWeight: 2,
  //     fillColor: theme.colors.primary.DEFAULT,
  //     fillOpacity: 0.35,
  //     map: map,
  //     center: $locations[$location].center,
  //     radius: Math.sqrt($locations[$location].radius) * 100
  //   });
  // }
  // // output each location as infowindow
  $locations.forEach(wrMarker);

  // /**
  //  * Polygons
  //  * Add array of coordinates to display a polygon on the map
  //  */
  //  // set the coordinates
  // var polyCoords = [
  //   {lat: 53.809975, lng: -1.550502}, // start in leeds
  //   {lat: 53.232986, lng: -0.540734}, // over to lincoln
  //   {lat: 52.773967, lng: -1.206344}, // down to loughborough
  //   {lat: 53.006427, lng: -2.179657}, // over to stoke
  //   {lat: 53.809975, lng: -1.550502}, // end in leeds
  // ];
  // // construct the polygon.
  // var $polyConstruct = new google.maps.Polygon({
  //   paths: polyCoords,
  //   strokeColor: 'theme.colors.primary.DEFAULT',
  //   strokeOpacity: 0.8,
  //   strokeWeight: 2,
  //   fillColor: 'theme.colors.primary.DEFAULT',
  //   fillOpacity: 0.35,
  // });
  // // insert polygon into the map
  // $polyConstruct.setMap(map);
  //
  //
  // /**
  //  * KML
  //  * Add array of KML files to be loaded with the map
  //  */
  // // set an array of KML files
  // var $areas = [
  //   'https://storage.googleapis.com/wr_kml/pumpgo.kml',
  // ];
  // // helper function
  // function wrKml(src) {
  //   new google.maps.KmlLayer(src, {
  //     suppressInfoWindows: true,
  //     preserveViewport: false,
  //     map: map,
  //   });
  // }
  // // create each KML layer
  // $areas.forEach(wrKml);
} // end initmap function

google.maps.event.addDomListener(window, "load", initGoogleMap);
