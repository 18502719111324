import { library, config, dom } from "@fortawesome/fontawesome-svg-core";
import {
  faQuoteLeft,
  faLongArrowLeft,
  faLongArrowRight,
  faLongArrowDown,
  faPhone,
  faEnvelope,
  faSpinnerThird,
  faFilePdf,
  faSearch,
  faChevronDoubleRight,
  faBolt,
  faExternalLink,
  faLink
} from "@fortawesome/pro-light-svg-icons";
import {
  faLinkedin,
  faTwitterSquare,
  faWhatsappSquare,
  faYoutubeSquare,
  faFacebookMessenger,
  faInstagramSquare,
  faFacebookSquare
} from "@fortawesome/free-brands-svg-icons";
library.add(
  faQuoteLeft,
  faLongArrowLeft,
  faLongArrowRight,
  faLongArrowDown,
  faSpinnerThird,
  faPhone,
  faEnvelope,
  faFilePdf,
  faSearch,
  faChevronDoubleRight,
  faBolt,
  faExternalLink,
  faLink,
  faLinkedin,
  faTwitterSquare,
  faWhatsappSquare,
  faYoutubeSquare,
  faFacebookMessenger,
  faInstagramSquare,
  faFacebookSquare
);
dom.watch();
config.searchPseudoElements = true;
