/**
 * Check if smooth scroll if supported
 */
export const SupportsSmoothScroll =
  "scrollBehavior" in document.documentElement.style;

/**
 * Extract a URL from a string
 */
export const urlRegex = /(https?:\/\/[^ ]*)/;

/**
 * Simulate a click event.
 * @public
 * @param {Element} elem  the element to simulate a click on
 */
export const simulateClick = function (elem) {
  // Create our event (with options)
  var evt = new MouseEvent("click", {
    bubbles: true,
    cancelable: true,
    view: window
  });
  // If cancelled, don't dispatch our event
  var canceled = !elem.dispatchEvent(evt);
};

/**
 * Document ready
 * @see https://tobiasahlin.com/blog/move-from-jquery-to-vanilla-javascript/#document-ready
 * @param {*} callback
 */
export const ready = (callback) => {
  if (document.readyState != "loading") callback();
  else document.addEventListener("DOMContentLoaded", callback);
};
