import "jquery-validation/dist/jquery.validate";
import getRecaptchaResponse from "./recaptcha";
import { ready } from "./helpers";
import * as FilePond from "filepond";
import FilePondPluginFileEncode from "filepond-plugin-file-encode";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
ready(() => {
  /**
   * Register filepond
   */
  FilePond.registerPlugin(
    // encodes the file as base64 data
    FilePondPluginFileEncode,

    // validates the type of the file
    FilePondPluginFileValidateType,

    // validates the size of the file
    FilePondPluginFileValidateSize,

    // corrects mobile image orientation
    FilePondPluginImageExifOrientation,

    // previews dropped images
    FilePondPluginImagePreview
  );

  // Select the file input and use create() to turn it into a pond
  var pond = FilePond.create(document.querySelector("input[type='file']"), {
    credits: false,
    allowMultiple: true,
    allowFileEncode: true,
    maxFiles: 3,

    // FilePondPluginFileValidateType
    acceptedFileTypes: [
      "image/png",
      "image/jpg",
      "image/jpeg",
      "image/gif",
      "image/vnd.microsoft.icon",
      "application/pdf",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "image/vnd.adobe.photoshop"
    ],
    labelFileTypeNotAllowed: "Sorry, this type of file is not allowed",

    // FilepondPluginFileValidateSize
    maxFileSize: "10MB",
    labelIdle: `<p class="mb-0">Drag &amp; Drop a file or <span class="filepond--label-action" tabindex="0">Browse</span></p><p style="font-size: 0.75rem" class="my-0">Maximum allowed size is 10mb</p><p style="font-size: 0.75rem" class="mt-0">Accepted file types are .jpg, .jpeg, .png, .gif, .ico, .pdf, .doc, .psd</p>`

    // onaddfilestart: () => {
    //   disableBetweenStartAndAddFilepond();
    // },
    // onprocessfiles: () => {
    //   disableBetweenStartAndAddFilepond();
    // },
    // onupdatefiles: () => {
    //   disableBetweenStartAndAddFilepond();
    // }
    // onaddfile: () => {
    //   disableBetweenStartAndAddFilepond();
    // }
  });

  // const disableBetweenStartAndAddFilepond = () => {
  //   // These are the statuses
  //   // EMPTY = 0,
  //   // IDLE = 1,
  //   // ERROR = 2,
  //   // BUSY = 3,
  //   // READY = 4
  //   let contactForm = document.querySelector(".contact_form");
  //   let contactFormSubmit = contactForm.querySelector("button[type='submit']");
  //   console.log(pond.status);
  //   if (pond.status == 3) {
  //     console.log("should be disabled. Status is - ", { ...pond.status });
  //     contactFormSubmit.disabled = true;
  //   } else {
  //     console.log("should be enabled. Status is - ", { ...pond.status });
  //     contactFormSubmit.disabled = false;
  //   }
  // };

  // load responses on init
  getRecaptchaResponse();

  // load responses again every 2 mins... then again after ajax calls
  setInterval(() => {
    getRecaptchaResponse();
  }, 2 * 60 * 1000);

  /**
   * Handle the forms
   * Using jquery validation for now, might change in future
   *
   * @param {Class with dot} formClass
   * @param {Ajax action to call} formAction
   * @param {Data layer form name for tag manager} dataLayerFormName
   * @param {Readable form name} readableFormName
   * */
  const formHandler = (
    formClass,
    formAction,
    dataLayerFormName,
    readableFormName
  ) => {
    const contactForm = document.querySelector(formClass);
    const jqContactForm = $(formClass);
    if (contactForm) {
      const contactFormSubmit = contactForm.querySelector(
        "button[type='submit']"
      );
      const contactFormSubmitOriginalHtml = contactFormSubmit.innerHTML;
      const contactFormAlert = contactForm.querySelector(
        ".success-error-message"
      );

      contactForm.addEventListener("submit", (e) => {
        e.preventDefault();

        jqContactForm.validate({
          errorPlacement: (er) => {
            er.hide();
          },
          highlight: (el) => {
            $(el).addClass("is-invalid").removeClass("is-valid");
          },
          unhighlight: (el) => {
            $(el).addClass("is-valid").removeClass("is-invalid");
          }
        });

        if (jqContactForm.valid()) {
          var formData = new FormData(contactForm);
          formData.append("action", formAction);
          formData.append("nonce", webroll_js_data.ajax_nonce);
          formData.append("readable_form_name", readableFormName);

          contactFormSubmit.disabled = true;
          contactFormSubmit.innerHTML =
            "Sending <i class='fal fa-spinner-third fa-spin'></i>";

          fetch(webroll_js_data.ajax_url, {
            method: "post",
            body: formData,
            mode: "cors",
            cache: "no-cache",
            credentials: "same-origin"
          })
            .then((response) => response.json())
            .then((data) => {
              contactFormSubmit.innerHTML = data.status;
              contactFormAlert.style.display = "block";
              contactFormAlert.innerHTML = data.message;
              data.status == "Success"
                ? contactFormAlert.classList.add(
                    "bg-success-light",
                    "text-success-dark",
                    "border-success"
                  )
                : contactFormAlert.classList.add(
                    "bg-danger-light",
                    "text-danger-dark",
                    "border-danger"
                  );
              // datalayer is throwing error in development. Add this only in prod
              if (typeof dataLayer !== "undefined") {
                dataLayer.push({
                  event: "wrFormSubmit",
                  formName: dataLayerFormName
                });
              }
              // reset the form data
              setTimeout(() => {
                contactForm.reset();
                contactFormSubmit.disabled = false;
                contactFormSubmit.innerHTML = contactFormSubmitOriginalHtml;
                contactFormAlert.style.display = "none";
                contactForm.querySelectorAll("input").forEach((i) => {
                  i.classList.remove("is-valid", "is-invalid");
                });
              }, 5000);
            })

            // reload the recaptcha inputs
            .then(() => getRecaptchaResponse())

            .catch((error) => {
              console.log(error);
              // console.log(error.name);
              // console.log(error.message);
              // console.log(error.stack);
            });
        }
      });
    }
  };
  formHandler(
    ".contact_form",
    "form_handler_callback",
    "contactForm",
    "Contact form"
  );
  formHandler(
    ".request_callback_form",
    "form_handler_callback",
    "requestCallbackForm",
    "Request callback form"
  );
  formHandler(
    ".quote_form",
    "form_handler_callback",
    "quoteForm",
    "Quote request form"
  );
});
