/**
 * External Dependencies
 */
import "jquery";

import "@fancyapps/fancybox/dist/jquery.fancybox.js";

import lazySizes from "lazysizes";
import "lazysizes/plugins/parent-fit/ls.parent-fit";
import "lazysizes/plugins/unveilhooks/ls.unveilhooks";
lazySizes.cfg.lazyClass = "lazy";
// lazySizes.cfg.expand = -10;
lazySizes.cfg.loadMode = 1;

import { gsap, Expo } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { DrawSVGPlugin } from "gsap/DrawSVGPlugin";
// import smoothscroll from "smoothscroll-polyfill";
import { ready } from "./helpers";

gsap.registerPlugin(ScrollTrigger, DrawSVGPlugin);

ScrollTrigger.defaults({
  toggleActions: "play pause resume reset"
});

import {
  Swiper,
  Navigation,
  Pagination,
  Autoplay,
  Thumbs,
  EffectFade
} from "swiper";
Swiper.use([Navigation, Pagination, Autoplay, Thumbs, EffectFade]);

import "./forms";
import "./font-awesome";
import "./map";

ready(() => {
  /**
   * Modal
   */
  var openModalBtn = document.querySelectorAll(".modal-open");
  if (openModalBtn) {
    const toggleModal = () => {
      const body = document.querySelector("body");
      const modal = document.querySelector(".modal");
      modal.classList.toggle("opacity-0");
      modal.classList.toggle("pointer-events-none");
      body.classList.toggle("modal-active");
    };

    openModalBtn.forEach((i) => {
      i.addEventListener("click", (e) => {
        e.preventDefault();
        toggleModal();
      });
    });

    const overlay = document.querySelector(".modal-overlay");
    overlay.addEventListener("click", toggleModal);

    var closemodal = document.querySelectorAll(".modal-close");
    closemodal.forEach((i) => {
      i.addEventListener("click", toggleModal);
    });

    document.onkeydown = function (evt) {
      evt = evt || window.event;
      var isEscape = false;
      if ("key" in evt) {
        isEscape = evt.key === "Escape" || evt.key === "Esc";
      } else {
        isEscape = evt.keyCode === 27;
      }
      if (isEscape && document.body.classList.contains("modal-active")) {
        toggleModal();
      }
    };
  }

  /**
   * Accordion
   */
  const accordionItems = Array.from(
    document.getElementsByClassName("accordion__item")
  );
  if (accordionItems) {
    accordionItems.map((i) => {
      i.addEventListener("click", () => {
        i.classList.remove("expanded-initially"); // remove the initial class as the height auto needs to be applied by gsap for future transitions
        var accContent = i.querySelector(".accordion__item__content");
        if (i.classList.contains("expanded")) {
          // same as wrFadeUpOut but includes the height: 0
          gsap.to(accContent, {
            y: -100,
            autoAlpha: 0,
            ease: Expo.easeInOut,
            height: "0"
          });
          accContent.classList.remove("active");
        } else {
          // same as wrFadeDownIn but includes the height: auto
          gsap.to(accContent, {
            y: 0,
            autoAlpha: 1,
            ease: Expo.easeInOut,
            height: "auto"
          });
          accContent.classList.add("active");
        }
        i.classList.toggle("expanded");
      });
    });
  }

  /**
   * Gsap
   */
  /**
   * Fade animations
   * Used in dropdown & search. When altering, test these components
   */
  const wrFadeUpOut = (elem) => {
    gsap.to(elem, {
      y: -100,
      autoAlpha: 0,
      ease: Expo.easeInOut
    });
    elem.classList.remove("active");
  };
  const wrFadeDownIn = (elem) => {
    gsap.to(elem, {
      y: 0,
      autoAlpha: 1,
      ease: Expo.easeInOut
    });
    elem.classList.add("active");
  };
  const wrFadeRightOut = (elem) => {
    gsap.to(elem, {
      x: 100,
      autoAlpha: 0,
      ease: Expo.easeInOut
    });
    elem.classList.remove("active");
  };
  const wrFadeLeftIn = (elem) => {
    gsap.to(elem, {
      x: 0,
      autoAlpha: 1,
      ease: Expo.easeInOut
    });
    elem.classList.add("active");
  };

  /**
   * Handle dropdown menus
   */
  const dropdownMenus = document.querySelectorAll(".menu-item.dropdown");
  if (dropdownMenus) {
    dropdownMenus.forEach((i) => {
      let dropdownToggle = i.children[0];
      let dropdownMenu = i.children[1];
      let applyIn = [dropdownToggle, dropdownMenu];
      let applyOut = [dropdownToggle, dropdownMenu];
      applyIn.forEach((i) => {
        i.addEventListener("mouseenter", () => {
          wrFadeDownIn(dropdownMenu);
          dropdownToggle.classList.add("active");
        });
      });
      applyOut.forEach((i) => {
        i.addEventListener("mouseleave", () => {
          wrFadeUpOut(dropdownMenu);
          dropdownToggle.classList.remove("active");
        });
      });
      dropdownToggle.addEventListener("click", () => {
        if (dropdownMenu.classList.contains("active")) {
          wrFadeUpOut(dropdownMenu);
          dropdownToggle.classList.remove("active");
        } else {
          wrFadeDownIn(dropdownMenu);
          dropdownToggle.classList.add("active");
        }
      });
    });
  }

  /**
   * Handle hamburger
   */
  const ham = document.querySelector("header .hamburger");
  const menu = document.querySelector("header nav .menu");
  ham.addEventListener("click", () => {
    ham.classList.toggle("active");
    menu.classList.toggle("active");
    document.body.classList.toggle("overflow-hidden");
  });

  /**
   * Search
   */
  const searchWrapper = document.querySelector("#search-wrapper");
  const searchForm = document.querySelector("#search-form");
  const searchResults = document.querySelector("#search-results");
  const searchToggle = document.querySelector("#search-toggle");
  const searchInput = document.querySelector("#search-input");
  const hamburger = document.querySelector(".hamburger");
  const header = document.querySelector(".header");
  if (searchForm) {
    gsap.set(searchWrapper, { autoAlpha: 0 });
    header.addEventListener("mouseleave", () => {
      wrFadeRightOut(searchWrapper);
    });
    searchToggle.addEventListener("mouseenter", () => {
      wrFadeLeftIn(searchWrapper);
    });
    searchWrapper.addEventListener("mouseenter", () => {
      wrFadeLeftIn(searchWrapper);
    });
    hamburger.addEventListener("mouseenter", () => {
      wrFadeRightOut(searchWrapper);
    });
    searchToggle.addEventListener("click", () => {
      if (searchWrapper.classList.contains("active")) {
        wrFadeRightOut(searchWrapper);
      } else {
        wrFadeLeftIn(searchWrapper);
      }
    });

    let timeout = null;

    searchForm.addEventListener("input", () => {
      clearTimeout(timeout);

      if (searchInput.value.length >= 3) {
        timeout = setTimeout(() => {
          $.ajax({
            url: webroll_js_data.ajax_url,
            type: "post",
            data: {
              action: "wrsearch",
              keyword: searchInput.value,
              nonce: webroll_js_data.ajax_nonce
            },
            beforeSend: () => {
              searchResults.classList.add(
                "flex",
                "items-center",
                "justify-center"
              );
              // searchResults.classList.remove("grid-6");
              searchResults.innerHTML =
                '<i class="fal fa-spinner-third fa-spin mt-3"></i>';
            },
            success: (data) => {
              searchResults.innerHTML = data;
              if (data.length === 0) {
                searchResults.innerHTML = `<span class="mt-3">No results</span>`;
              } else {
                searchResults.classList.remove(
                  "flex",
                  "items-center",
                  "justify-center"
                );
                // searchResults.classList.add("grid-6");
              }
            }
          });
        }, 500);
      } else {
        searchResults.innerHTML = "";
      }
    });
  }

  /**
   * Cookies
   */
  const removeCookiesFromDom = () => {
    cookies.remove();
  };
  const cookies = document.getElementById("cookies");
  if (cookies) {
    let cookiesAccept = document.getElementById("cookiesAccept");
    let currentCookies = document.cookie;

    if (!currentCookies.includes("cookiesAccept=true")) {
      cookies.style.display = "block";
      cookiesAccept.addEventListener("click", () => {
        var cookieDate = new Date();
        cookieDate.setMonth(cookieDate.getMonth() + 12);

        document.cookie =
          "cookiesAccept=true" + ";expires=" + cookieDate + ";path=/";
        gsap.to(cookies, {
          x: -1000,
          autoAlpha: 0,
          onComplete: removeCookiesFromDom
        });
      });
    } else {
      removeCookiesFromDom();
    }
  }

  /**
   * Swiper
   */
  let swiperWrapper = document.querySelectorAll(".swiper-wrapper");
  if (swiperWrapper.length >= 1) {
    swiperWrapper.forEach((i) => {
      i.nextElementSibling.classList.contains("swiper-pagination")
        ? i.classList.add("mb-6")
        : null;
    });
  }

  // include like this twice because the swiper-container class is on the same level on sliders
  new Swiper(".hero_slider .swiper-container, .hero_slider.swiper-container", {
    grabCursor: true,
    watchSlidesProgress: true,
    watchSlidesVisibility: true,
    loop: true,
    // Disable preloading of all images
    preloadImages: false,
    // Enable lazy loading
    lazy: true,
    autoplay: {
      delay: 5000,
      disableOnInteraction: false
    },
    // scrollbar: {
    //   el: ".swiper-scrollbar",
    //   draggable: true
    // }
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev"
    }
  });

  new Swiper(".wr_image_gallery.swiper-container", {
    loop: true,
    autoHeight: true,
    grabCursor: true,
    watchSlidesProgress: true,
    watchSlidesVisibility: true,
    // Disable preloading of all images
    preloadImages: false,
    // Enable lazy loading
    lazy: true,
    autoplay: {
      delay: 5000,
      disableOnInteraction: false
    },
    effect: "fade",
    fadeEffect: {
      crossFade: true
    },
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev"
    },
    slidesPerView: 1,
    spaceBetween: 0
  });

  /*
   * Add class to image types with no content above it
   * By targeting [class*="image"] we are targeting - .wr_image, .wr_image_background, .wr_image_grid,
   */
  $(
    ".wr_section > .wr_container > .wr_row > .wr_image, .wr_section > .wr_container > .wr_row > .wr_grid, .wr_section > .wr_container > .wr_row > .wr_grid__slider, .wr_section > .wr_container > .wr_row > .wr_image + .wr_grid"
  ).each(function () {
    if (typeof $(this).prev().attr("class") == "undefined") {
      $(this).addClass("noContent");
    }
  });

  /**
   * Fancybox
   */
  // protect images from being downloaded
  $("[data-fancybox]").fancybox({
    protect: true
  });

  /**
   * Sticky menu
   */
  function debounce(func, wait, immediate) {
    var timeout;
    return function () {
      var context = this,
        args = arguments;
      var later = function () {
        timeout = null;
        if (!immediate) func.apply(context, args);
      };
      var callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
      if (callNow) func.apply(context, args);
    };
  }

  // use the debounce function to calc height of header then apply as padding
  // if (header) {
  //   var sticky_debounce = debounce(function () {
  //     const main = document.querySelector("main");
  //     var height = header.offsetHeight;

  //     if (window.scrollY >= 400) {
  //       header.classList.add("stuck");
  //       gsap.to(header, {
  //         y: 100,
  //         autoAlpha: 0,
  //         ease: Expo.easeInOut,
  //         height: "auto"
  //       });
  //       main.style.paddingTop = height;
  //     } else {
  //       header.classList.remove("stuck");
  //       // gsap.to(header, {
  //       //   y: 0,
  //       //   autoAlpha: 1,
  //       //   ease: Expo.easeInOut,
  //       //   height: "auto"
  //       // });
  //       main.style.paddingTop = "0";
  //     }
  //   }, 250);
  //   window.addEventListener("scroll", sticky_debounce);
  // }

  /*
   * News.js
   */
  var ppp = $("#ppp").val(); // how many to load
  var pageNumber = 1; // which page number to start loading from
  var total = $("#totalpages").val();
  var loadMore = $("#load_more");
  var cat = $("#category-id").data("category");
  var nonce = $("#category-id").data("nonce");
  var postType = $("#category-id").data("post-type");

  loadMore.on("click", function () {
    loadMore
      .attr("disabled", true)
      .html('Loading <i class="fal fa-spinner-third fa-spin"></i>');
    pageNumber++;
    $.ajax({
      type: "POST",
      dataType: "html",
      /* eslint-disable-next-line */
      url: webroll_js_data.ajax_url,
      data: {
        pageNumber: pageNumber,
        ppp: ppp,
        action: "load_news_ajax",
        cat: cat,
        nonce: nonce,
        postType: postType
      },
      success: function (data) {
        // first replace the class to allow us to fade in
        var $data = data.replace(
          /article class="post-preview/g,
          'article class="post-preview animate-fadeInUp'
        );

        if ($data.length) {
          $data =
            $data[$data.length - 1] == "0"
              ? $data.substring(0, $data.length - 1)
              : $data;
          $("#indexWrapper").append($data);
          loadMore
            .attr("disabled", false)
            .html("Load more <i class='fal fa-long-arrow-down'></i>");
        }
        if (total < pageNumber + 1) {
          loadMore
            .attr("disabled", true)
            .html("Finished loading")
            .delay(5000)
            .fadeOut(1500);
        }
      }
    });
    return false;
  });
}); // end document ready
